import { Observable, map } from 'rxjs';

import { DEBUG_FETCHES } from 'env';
import { fromFetch } from 'utils/rxjs';

export type FetchOptions<I> = {
  makeUrl: (input: I) => string;
  makeRequestInit: (input: I) => RequestInit;
};

type FetchMeta = {
  url: string;
  requestInit: RequestInit;
  response?: Response;
  start: Date;
  end?: Date;
};

const fetches: FetchMeta[] = [];

export function handleFetch<I>(input: I, options: FetchOptions<I>): Observable<Response> {
  const { makeRequestInit, makeUrl } = options;

  if (!DEBUG_FETCHES) {
    return fromFetch(makeUrl(input), makeRequestInit(input));
  }

  return handleFetchDebug(input, options);
}

function handleFetchDebug<I>(input: I, options: FetchOptions<I>): Observable<Response> {
  const { makeRequestInit, makeUrl } = options;
  const url = makeUrl(input);
  const requestInit = makeRequestInit(input);
  const fetchIndex = fetches.length;

  fetches.push({
    url,
    requestInit,
    start: new Date(),
  });

  return fromFetch(url, requestInit).pipe(
    map(response => {
      fetches[fetchIndex].response = response;
      fetches[fetchIndex].end = new Date();
      return response;
    }),
  );
}

/* eslint-disable no-console */
export function printFetches(label: string) {
  console.log(`\n#### Fetches ${label} ####`);

  fetches.forEach(({ requestInit, start, url, end, response }, index) => {
    console.log(`\n${index}. ${url}`);

    console.log(`- method: ${requestInit.method || 'GET'}`);
    response?.status && console.log(`- status: ${response.status}`);
    requestInit.body && console.log(`- req body: ${requestInit.body}`);
    end && console.log(`- duration: ${end.getTime() - start.getTime()}ms`);
    console.log(`- start: ${start.getSeconds()}.${start.getMilliseconds()}`);
    end && console.log(`- end: ${end.getSeconds()}.${end.getMilliseconds()}`);
  });

  console.log('');

  const minStartTime = [...fetches].sort((a, b) => a.start.getTime() - b.start.getTime())[0]?.start;
  const maxEndTime = [...fetches].sort((a, b) =>
    a.end && b.end ? b.end.getTime() - a.end.getTime() : -1,
  )[0]?.end;

  if (minStartTime && maxEndTime) {
    console.log(`Total start: ${minStartTime.getSeconds()}.${minStartTime.getMilliseconds()}`);
    console.log(`Total end: ${maxEndTime.getSeconds()}.${maxEndTime.getMilliseconds()}`);
    console.log(`Total duration: ${maxEndTime.getTime() - minStartTime.getTime()}ms\n`);
  }

  fetches.forEach(({ start, end }, index) => {
    const startTime = `${start.getSeconds()}.${start.getMilliseconds()}${'0'.repeat(
      3 - start.getMilliseconds().toString().length,
    )}`;
    const endTime = end
      ? `${end.getSeconds()}.${end.getMilliseconds()}${'0'.repeat(
          3 - end.getMilliseconds().toString().length,
        )}`
      : '–';
    const duration = end ? `${end.getTime() - start.getTime()}ms` : '–';
    console.log(`${index} \t ${startTime} \t ${endTime} \t ${duration}`);
  });

  fetches.length = 0;
}
