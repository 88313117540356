import { useState, useMemo, useRef, useEffect } from 'react';

import { RemoteData, success, failure, loading, isSuccess } from '../remoteData';
import { usePrevious } from './usePrevious';

export type Options<R> = {
  initial?: RemoteData<R>;
  resetOnChangeTarget?: boolean;
  clientOnly?: boolean;
};

export function useSubscribable<R>(
  getTarget: () => Subscribable<R>,
  deps: any[],
  options: Options<R> = {},
): RemoteData<R> {
  const defaultOptions: Required<Options<R>> = {
    initial: loading,
    resetOnChangeTarget: true,
    clientOnly: false,
  };
  const { initial, resetOnChangeTarget, clientOnly } = {
    ...defaultOptions,
    ...options,
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const target = useMemo(getTarget, deps);
  const prevTarget = usePrevious(target);

  const [, setRenderTrigger] = useState(() => 0);
  const unsubscribeRef = useRef<(() => void) | null>(null);
  const valueRef = useRef<RemoteData<R>>(initial);

  const mountedRef = useRef(false);
  const mounted = mountedRef.current;
  const prevMounted = usePrevious(mountedRef.current);
  useEffect(() => {
    mountedRef.current = true;
    setRenderTrigger(cur => cur + 1);
  }, []);

  if (prevTarget !== target || (!prevMounted && mounted)) {
    unsubscribeRef.current && unsubscribeRef.current();
    let subscribed = false;

    if (resetOnChangeTarget) {
      valueRef.current = initial;
    } else if (isSuccess(valueRef.current)) {
      valueRef.current = success(valueRef.current.value.data, true);
    }

    if (!clientOnly || mounted) {
      const subscription = target.subscribe({
        next: nextValue => {
          valueRef.current = success(nextValue);
          if (subscribed) {
            mountedRef.current && setRenderTrigger(cur => cur + 1);
          }
        },
        error: err => {
          valueRef.current = failure(err);
          if (subscribed) {
            mountedRef.current && setRenderTrigger(cur => cur + 1);
          }
          if (process.env.NODE_ENV === 'development') {
            console.error(err);
          }
        },
      });

      subscribed = true;

      unsubscribeRef.current = () => subscription.unsubscribe();
    }
  }

  return valueRef.current;
}

interface Unsubscribable {
  unsubscribe(): void;
}

interface Observer<T> {
  next: (value: T) => void;
  error: (err: any) => void;
  complete: () => void;
}

interface Subscribable<T> {
  subscribe(observer?: Partial<Observer<T>>): Unsubscribable;
  subscribe(): Unsubscribable;
}
