import { useRef, useState } from 'react';

import { usePrevious } from 'utils/react';

export function useOptimistic<V, I>(
  value: V,
  getOptimisticValue: (current: V, input: I) => V,
): [value: V, applyOptimistic: (input: I) => void, isOptimistic: boolean] {
  const prevValue = usePrevious(value);
  const optimisticValue = useRef(value);
  const [, setRenderTrigger] = useState(() => 0);

  if (prevValue !== value) {
    optimisticValue.current = value;
  }

  return [
    optimisticValue.current,
    (input: I) => {
      optimisticValue.current = getOptimisticValue(value, input);
      setRenderTrigger(cur => cur + 1);
    },
    optimisticValue.current !== value,
  ];
}
