// eslint-disable-next-line no-restricted-imports
import { ReplaySubject, ShareConfig, timer } from 'rxjs';

export function resetOnZeroRefs<T>(resetDelay: number = 60 * 1000): ShareConfig<T> {
  return {
    connector: () => new ReplaySubject(1),
    resetOnError: true,
    resetOnComplete: false,
    resetOnRefCountZero: () => timer(resetDelay),
  };
}
