import { Token } from '@akropolis-web/primitives';

import { asNetworkSlug, asProductSlug, asVersionSlug } from 'domain/types/slug';

export const LENDING_TOKEN_BY_LENDING_SLUG: Partial<Record<string, Token>> = {
  silo: new Token(
    '0xd7c9f0e536dc865ae858b0c0453fe76d13c3beac',
    'XAI',
    18,
    asNetworkSlug('mainnet'),
  ),
};

export const SILO_SLUG = asProductSlug('silo');
export const SILO_NETWORK_SLUG = asNetworkSlug('mainnet');
export const SILO_VERSION_SLUG = asVersionSlug('default');
