import { Observable, map } from 'rxjs';

import { Either } from 'utils/either';

export function unpackEither<T>(observable: Observable<Either<T>>): Observable<T> {
  return observable.pipe(
    map(value =>
      value.fold(
        v => v,
        error => {
          throw new Error(error);
        },
      ),
    ),
  );
}
