import { useRef } from 'react';

export function usePrevious<T>(value: T): T | undefined {
  const refCurrent = useRef<T>();
  const refPrev = useRef<T>();

  refPrev.current = refCurrent.current;
  refCurrent.current = value;

  return refPrev.current;
}
