/* eslint-disable class-methods-use-this */
import { Observable, interval } from 'rxjs';
import { map, share, startWith, switchMap } from 'rxjs/operators';

import { resetOnZeroRefs } from 'utils/rxjs';

import { AbstractHandler } from './AbstractHandler';
import { FetchOptions, handleFetch, OverrideResponseOptions, handleParseJson } from './handlers';
import { CheckExpectedOutput, CheckUnexpectedOutput, neverSymbol } from './types';

type UnpackObservable<O> = O extends Observable<infer T> ? T : never;

export class Handler<I, O> extends AbstractHandler<I, O> {
  static new<I>(): Handler<I, typeof neverSymbol> {
    return new Handler<I, typeof neverSymbol>(() => neverSymbol);
  }

  private constructor(private handler: (input: I) => O) {
    super();
  }

  fetch(
    makeUrl: FetchOptions<I>['makeUrl'],
    makeRequestInit: FetchOptions<I>['makeRequestInit'],
  ): CheckExpectedOutput<O, typeof neverSymbol, Handler<I, Observable<Response>>> {
    return new Handler((input: I) => handleFetch(input, { makeUrl, makeRequestInit }));
  }

  parseJson<T extends Record<200 | number, unknown>>(
    overrides: OverrideResponseOptions<I, T> = {},
  ): CheckExpectedOutput<O, Observable<Response>, Handler<I, Observable<T[200]>>> {
    return new Handler((input: I) =>
      handleParseJson(input, overrides, this as Handler<I, Observable<Response>>),
    );
  }

  map<NO>(
    mapFn: (output: UnpackObservable<O>) => NO,
  ): CheckExpectedOutput<O, Observable<any>, Handler<I, Observable<NO>>> {
    return new Handler((input: I) => (this.execute(input) as Observable<any>).pipe(map(mapFn)));
  }

  memoizeWith(
    makeKey: (input: I) => string,
  ): CheckExpectedOutput<O, Observable<any>, Handler<I, O>> {
    const cache: Record<string, O> = {};

    return new Handler((input: I) => {
      const memoKey = makeKey(input);
      cache[memoKey] =
        cache[memoKey] ||
        ((this.execute(input) as Observable<any>).pipe(share(resetOnZeroRefs())) as O);
      return cache[memoKey] as O;
    });
  }

  /** @dev Should be used before memoizeWith */
  refetchByTimeout(timeout: number): CheckExpectedOutput<O, Observable<any>, Handler<I, O>> {
    return this.refetchByStream(interval(timeout));
  }

  /** @dev Should be used before memoizeWith */
  refetchByStream(stream: Observable<any>): CheckExpectedOutput<O, Observable<any>, Handler<I, O>> {
    return new Handler(
      (input: I) =>
        stream.pipe(
          startWith(true),
          switchMap(() => this.execute(input) as Observable<any>),
        ) as O,
    );
  }

  execute(input: I): CheckUnexpectedOutput<O, typeof neverSymbol, O> {
    return this.handler(input);
  }

  toFn(): (input: I) => CheckUnexpectedOutput<O, typeof neverSymbol, O> {
    return (input: I) => this.handler(input);
  }
}
