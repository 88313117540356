import { logger } from 'core/logger';

import { RestSDKError } from './RestSDKError';

export async function parseJson<T extends Record<200 | number, unknown>>(
  response: Response,
): Promise<T[200]> {
  if (!response.ok) {
    const error = new RestSDKError(response);
    if (response.status >= 500) {
      logger.captureError(error);
    }
    throw error;
  }

  return response.json();
}
