export const PRIVACY_NOTICE_URL =
  'https://legal.apostro.xyz/Apostro%20Privacy%20Notice%202022-09-30.pdf';
export const LEGAL_NOTICE_URL =
  'https://legal.apostro.xyz/Apostro%20Legal%20Notice%202022-09-30.pdf';
export const PROPOSE_PROTOCOL_URL = 'https://eivvzoupoci.typeform.com/to/ontuvwjK';
export const APOSTRO_GLOSSARY_IMPACTCOST_URL =
  'https://docs.apostro.xyz/resources/apostro-algorithms#market-impact';
export const APOSTRO_GLOSSARY_UB_MODEL_URL =
  'https://docs.apostro.xyz/resources/apostro-algorithms#upper-bound';
export const APOSTRO_GLOSSARY_UMI_MODEL_URL =
  'https://docs.apostro.xyz/resources/apostro-algorithms#uniswap-v3';
export const RATING_ANALYZE_URL = 'https://docs.apostro.xyz/resources/rating-definitions';
export const ABOUT_US = 'https://www.apostro.xyz/#about-us';
export const GET_IN_TOUCH = 'mailto:gm@apostro.xyz';
export const BLOG = 'https://www.apostro.xyz/articles';
export const PROD_APP_URL = 'https://app.apostro.xyz';
export const JOIN_WAIT_LIST_URL = 'https://www.apostro.xyz/join-waitlist';
export const APOSTRO_LANDING_URL = 'https://www.apostro.xyz/';
export const TWITTER_URL = 'https://twitter.com/apostroxyz';
export const TELEGRAM_URL = 'https://t.me/apostroxyz';
