import { Observable, of, startWith } from 'rxjs';
import { parse } from 'superjson';

import { IS_BROWSER } from 'env/projectSettings';
import { RemoteData } from 'utils/remoteData';

import { Options, useSubscribable } from './useSubscribable';

export function useServerData<T>(
  getTarget: () => Observable<T>,
  deps: any[],
  data: string,
  options: Omit<Options<T>, 'clientOnly' | 'initial'> = {},
): RemoteData<T> {
  const parsedData: T = parse(data);

  return useSubscribable(
    () => (IS_BROWSER ? getTarget().pipe(startWith(parsedData)) : of(parsedData)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
    options,
  );
}
