import {
  combineLatest,
  fromEvent,
  merge,
  of,
  // eslint-disable-next-line no-restricted-imports
  timer as rxTimer,
} from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith } from 'rxjs/operators';

import { IS_BROWSER } from 'env/projectSettings';

const tabVisible$ = (
  IS_BROWSER
    ? merge(
        fromEvent(document, 'visibilitychange').pipe(
          map(() => document.visibilityState === 'visible'),
        ),
        fromEvent(window, 'pagehide').pipe(map(() => false)),
        fromEvent(window, 'pageshow').pipe(map(() => true)),
      ).pipe(distinctUntilChanged(), startWith(true))
    : of(true)
).pipe(shareReplay(1));

export const timer: typeof rxTimer = (...args: [any, any]) =>
  combineLatest([rxTimer(...args), tabVisible$]).pipe(
    filter(([, tabVisible]) => tabVisible),
    map(([value]) => value),
    distinctUntilChanged(),
  );
