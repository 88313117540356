export type ProductSlug = `${string}_product_slug`;
export type VersionSlug = `${string}_version_slug`;
export type NetworkSlug = `${string}_network_slug`;

export type LendingSlugs = {
  productSlug: ProductSlug;
  versionSlug: VersionSlug;
  network: NetworkSlug;
};

export function asProductSlug(value: string): ProductSlug {
  return value as ProductSlug;
}

export function asVersionSlug(value: string): VersionSlug {
  return value as VersionSlug;
}

export function asNetworkSlug(value: string): NetworkSlug {
  return value as NetworkSlug;
}
