import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AbstractHandler } from '../AbstractHandler';
import { parseJson } from '../parseJson';

export type OverrideResponseOptions<I, T extends Record<200 | number, unknown>> = {
  [key in keyof T]?: (input: I, response: Response) => T[200] | Promise<T[200]>;
};

export function handleParseJson<I, T extends Record<200 | number, unknown>>(
  input: I,
  overrides: OverrideResponseOptions<I, T>,
  sourceHandler: AbstractHandler<I, Observable<Response>>,
) {
  return sourceHandler.execute(input).pipe(
    switchMap((response: Response): Promise<T[200]> => {
      const overrideOutput = overrides?.[response.status as keyof T];
      if (overrideOutput) {
        return Promise.resolve(overrideOutput(input, response));
      }
      return parseJson(response);
    }),
  );
}
