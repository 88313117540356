import React from 'react';
import { Alert, Skeleton, SkeletonProps } from '@mui/material';
import Error from '@mui/icons-material/Error';

import { isSuccess, RemoteData } from 'utils/remoteData';
import { getErrorMsg } from 'utils/getErrorMsg';

type Props<R> = {
  data: R | RemoteData<R>;
  children: (data: R, loading?: boolean) => JSX.Element | null;
  progressProps?: SkeletonProps;
  component?: React.ComponentType;
  loader?: React.ReactNode;
  waiter?: React.ReactNode;
};

export function Loading<R>(props: Props<R>) {
  const { data: rd, children, loader, waiter, progressProps, component } = props;

  const Wrapper = component || React.Fragment;

  if (!(rd instanceof RemoteData)) {
    return children(rd);
  }

  return rd.fold(
    () => <Wrapper>{waiter || <div>Initializing...</div>}</Wrapper>,
    () => (
      <Wrapper>
        {typeof loader !== 'undefined' ? (
          loader
        ) : (
          <Skeleton {...(progressProps as SkeletonProps)} />
        )}
      </Wrapper>
    ),
    error => (
      <Wrapper>
        <Alert color="error" icon={<Error />}>
          {getErrorMsg(error)}
        </Alert>
      </Wrapper>
    ),
    data => children(data, isSuccess(rd) ? rd.value.loading : undefined),
  );
}
