import { Observable, ReplaySubject, filter } from 'rxjs';

type CallSpy<F> = [
  F,
  {
    allCalls$: Observable<CallResult<ExtractReturn<F>>>;
    executedCalls$: Observable<CallExecuted<ExtractReturn<F>>>;
    failedCalls$: Observable<CallFailed>;
  },
];

export function makeCallSpy<F extends (...args: any[]) => Promise<any>>(fn: F): CallSpy<F> {
  const allCalls$ = new ReplaySubject<CallResult<ExtractReturn<F>>>();
  const executedCalls$ = allCalls$.pipe(filter(isCallExecuted));
  const failedCalls$ = allCalls$.pipe(filter(isCallFailed));

  return [
    ((...args) =>
      fn(...args)
        .then(data => {
          allCalls$.next({
            type: 'EXECUTED',
            data,
          });
          return data;
        })
        .catch(error => {
          allCalls$.next({
            type: 'FAILED',
            error,
          });
          throw error;
        })) as F,
    { allCalls$, executedCalls$, failedCalls$ },
  ];
}

type CallExecuted<D> = {
  type: 'EXECUTED';
  data: D;
};

type CallFailed = {
  type: 'FAILED';
  error: unknown;
};

type CallResult<D> = CallExecuted<D> | CallFailed;

function isCallExecuted<D>(call: CallResult<D>): call is CallExecuted<D> {
  return call.type === 'EXECUTED';
}

function isCallFailed(call: CallResult<any>): call is CallFailed {
  return call.type === 'FAILED';
}

type ExtractReturn<F> = F extends (...args: any[]) => Promise<infer R> ? R : never;
