import { APOSTRO_API_URL } from 'env';
import { Storage, localStorageAdapter } from 'core/storage';

export const BASE_URL = `${APOSTRO_API_URL}/api`;

export const headers = {
  'content-type': 'application/json',
};

export function getPrivateHeaders() {
  const token = authStorage.getItem('access');
  return token ? { ...headers, Authorization: `Bearer ${token}` } : headers;
}

export function getRefreshHeaders() {
  const token = authStorage.getItem('refresh');
  return token ? { ...headers, Authorization: `Bearer ${token}` } : headers;
}

export type AuthStorage = {
  access: string | null;
  refresh: string | null;
};

export const authStorage = new Storage<[AuthStorage]>(
  'rest-auth',
  localStorageAdapter,
  { access: null, refresh: null },
  [],
);
